import React from 'react';
import { SvgIcon } from '@mui/material';

// Custom UpArrowIcon component
export const UpArrowIcon = (props) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="12" r="10" fill="#e6f4ea" />
    <path d="M12 7l-5 5h10l-5-5z" fill="#34a853" />
  </SvgIcon>
);

// Custom DownArrowIcon component
export const DownArrowIcon = (props) => (
  <SvgIcon {...props}>
    <circle cx="12" cy="12" r="10" fill="#fce8e6" />
    <path d="M12 17l5-5H7l5 5z" fill="#ea4335" />
  </SvgIcon>
);
