import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const VideoSection = React.memo(({ videoUrl }) => {
  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 1 }}>
        Video
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'grey.200',
          borderRadius: 2,
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {videoUrl ? (
          <video
            src={videoUrl}
            type="mp4"
            controls
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          >
            Your browser does not support the video tag.
          </video>
        ) : (
          <Typography variant="body1" color="text.secondary">
            No video available
          </Typography>
        )}
      </Box>
    </Box>
  );
});

VideoSection.displayName = 'Video';
VideoSection.propTypes = {
  videoUrl: PropTypes.string,
};

export default VideoSection;
