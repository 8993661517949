import { styled } from '@mui/material/styles';
import { Tabs, Tab, Button, TableCell, Box, ListItem } from '@mui/material';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.warning.main,
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  '&.Mui-selected': {
    color: theme.palette.warning.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontWeight: 'bold',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1.5),
  '&.column-email': {
    width: '30%',
  },
  '&.column-date': {
    width: '15%',
  },
  '&.column-attempt': {
    width: '10%',
  },
  '&.column-score': {
    width: '25%',
  },
  '&.column-action': {
    width: '15%',
  },
}));

export const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1.5),
}));

export const DetailButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontWeight: 'bold',
  borderColor: theme.palette.grey[300],
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

export const ContentBox = styled(Box)(({ theme }) => ({
  maxWidth: '1900px',
  margin: '0 auto',
  padding: theme.spacing(3),
  backgroundColor: '#ffffff',
  borderRadius: '16px',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
}));

export const NumberedButton = styled(Button)(({ theme, active }) => ({
  minWidth: '30px',
  width: '30px',
  height: '30px',
  padding: 0,
  margin: '0 4px',
  color: active ? theme.palette.common.white : theme.palette.warning.main,
  backgroundColor: active ? theme.palette.warning.main : theme.palette.common.white,
  border: `1px solid ${theme.palette.warning.main}`,
  '&:hover': {
    backgroundColor: active ? theme.palette.warning.dark : theme.palette.warning.light,
    color: theme.palette.common.white,
  },
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '29px',
    top: 0,
    bottom: 0,
    width: '1px',
    borderLeft: `2px dotted ${theme.palette.divider}`,
    zIndex: 1,
  },
  '&:last-child::before': {
    display: 'none',
  },
}));

export const StyledListItemForQuestions = styled('li')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 0,
  marginBottom: '5.5vh', // Add some bottom margin for spacing
  '&:not(:last-child)::after': {
    content: '""',
    position: 'absolute',
    left: '17px',
    top: '30px',
    height: 'calc(100% + 5.5vh)', // Extend to the next item, accounting for marginBottom
    width: '0px',
    borderLeft: `2px double ${theme.palette.warning.main}`,
  },
}));
