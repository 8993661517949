import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@mui/material';
import EditButton from '../Elements/EditButton';
import { DonutChart } from '../Elements/DonutChart';
import EditDonuts from '../Elements/EditDonuts';
import { useReportHandlers } from '../../Handlers';

const getColor = (value) => {
  if (value < 40) return '#ba3519';
  if (value < 70) return '#de7b1d';
  return '#39bf19';
};

const ReportSection = React.memo(({ report, onUpdateReport }) => {
  const { isEditDialogOpen, handleEditClick, handleCloseDialog, handleSaveMetrics } =
    useReportHandlers(onUpdateReport);

  const metrics = useMemo(
    () =>
      report
        ? Object.entries(report).map(([key, value]) => ({
            name: key.split(/(?=[A-Z])/).join(' '),
            value: Number(value),
          }))
        : [],
    [report],
  );

  return (
    <Box
      sx={{
        height: '100%',
        bgcolor: 'background.paper',
        borderRadius: 2,
        p: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="subtitle1" fontWeight="bold">
          Report
        </Typography>
        <EditButton onClick={handleEditClick} />
      </Box>
      {metrics.length > 0 ? (
        <Grid
          container
          spacing={4.5}
          sx={{
            flexGrow: 1,
            alignItems: 'center',
            mt: '-5vh',
          }}
        >
          {metrics.map((metric, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <DonutChart value={metric.value} color={getColor(metric.value)} name={metric.name} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
          <Typography variant="body1" color="text.secondary">
            No report data available
          </Typography>
        </Box>
      )}
      <EditDonuts
        open={isEditDialogOpen}
        onClose={handleCloseDialog}
        metrics={metrics}
        onSave={handleSaveMetrics}
      />
    </Box>
  );
});

ReportSection.propTypes = {
  report: PropTypes.object,
  onUpdateReport: PropTypes.func.isRequired,
};

ReportSection.displayName = 'ReportSection';

export default ReportSection;
