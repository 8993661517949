import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';

const RecentSubscriptions = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  // Mock data - in a real application, this would come from an API or props
  const subscriptionsData = [
    {
      id: 1,
      user: 'Sam Smith',
      subscription: 'Trial',
      created: '2023-01-15',
      duration: '3 weeks',
      status: 'Active',
      amount: 'Free',
    },
    {
      id: 2,
      user: 'John Doe',
      subscription: 'Basic',
      created: '2023-01-15',
      duration: '12 months',
      status: 'Expired',
      amount: '60$',
    },
    {
      id: 3,
      user: 'Micheal Jackson',
      subscription: 'Trial',
      created: '2023-01-15',
      duration: '3 weeks',
      status: 'Active',
      amount: 'Free',
    },
    {
      id: 4,
      user: 'Emma Brown',
      subscription: 'Basic',
      created: '2023-01-15',
      duration: '2 months',
      status: 'Active',
      amount: '10$',
    },
    {
      id: 5,
      user: 'James Wilson',
      subscription: 'Trial',
      created: '2023-01-15',
      duration: '3 weeks',
      status: 'Active',
      amount: 'Free',
    },
  ];

  const handleOpenDialog = (subscription) => {
    setSelectedSubscription(subscription);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box sx={{ p: 1.5, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant="subtitle2" component="h2" sx={{ fontWeight: 'medium' }}>
          Recent Subscriptions
        </Typography>
        <Tooltip title="Latest user subscriptions and their details">
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ flexGrow: 1, border: 'none', boxShadow: 'none' }}
        elevation={0}
      >
        <Table sx={{ minWidth: 650 }} aria-label="recent subscriptions table">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptionsData.map((row) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.user}
                </TableCell>
                <TableCell>{row.subscription}</TableCell>
                <TableCell>{row.created}</TableCell>
                <TableCell>{row.duration}</TableCell>
                <TableCell>
                  <Chip
                    label={row.status}
                    size="small"
                    sx={{
                      color: row.status === 'Active' ? 'green' : 'red',
                      border: `1px solid ${row.status === 'Active' ? 'green' : 'red'}`,
                      backgroundColor: 'white',
                    }}
                  />
                </TableCell>

                <TableCell>{row.amount}</TableCell>
                <TableCell>
                  <IconButton size="small" onClick={() => handleOpenDialog(row)}>
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Subscription Details</DialogTitle>
        <DialogContent>
          {selectedSubscription && (
            <Box>
              <Typography variant="body1" gutterBottom>
                <strong>User:</strong> {selectedSubscription.user}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Subscription:</strong> {selectedSubscription.subscription}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Created:</strong> {selectedSubscription.created}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Duration:</strong> {selectedSubscription.duration}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Status:</strong> {selectedSubscription.status}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Amount:</strong> {selectedSubscription.amount}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            sx={{
              textTransform: 'none',
              color: '#e0680a',
              borderColor: 'black',
              '&:hover': {
                borderColor: 'black',
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RecentSubscriptions;
