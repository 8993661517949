import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  Popover,
  Button,
  styled,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import FeedbackIcon from '@mui/icons-material/Feedback';
import safespacelogo from '../assets/safe-space.png';
import avatarImage from '../assets/anish.png';

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'button',
})(({ theme, isActive }) => ({
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(0),
  backgroundColor: isActive ? alpha(theme.palette.warning.main, 0.1) : 'transparent',
  color: isActive ? theme.palette.warning.main : theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: isActive ? alpha(theme.palette.warning.main, 0.2) : theme.palette.action.hover,
  },
  '& .MuiListItemIcon-root': {
    color: isActive ? theme.palette.warning.main : theme.palette.text.secondary,
    minWidth: 40,
  },
  '& .MuiListItemText-primary': {
    fontWeight: isActive ? 700 : 400,
    color: theme.palette.text.secondary,
  },
}));

const Layout = ({ children, onSignOut }) => {
  // Receive onSignOut as a prop
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  // Mock user data - replace with actual user data
  const user = {
    name: 'Anish Suri',
    email: 'anish.suri@example.com',
    avatar: avatarImage, // Use the imported image here
  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleSignOutClick = () => {
    // Renamed to avoid confusion
    console.log('Signing out...');
    onSignOut(); // Call the sign out function passed from App.js
    handlePopoverClose();
    navigate('/login'); // Redirect to login page
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', bgcolor: 'white' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
        <img src={safespacelogo} alt="Safe Space Logo" style={{ height: 80 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', mr: '1cm' }}>
          <IconButton onClick={() => navigate('/settings')} sx={{ mr: 0.1 }}>
            <SettingsIcon />
          </IconButton>
          <IconButton onClick={() => navigate('/notifications')} sx={{ mr: 0.1 }}>
            <NotificationsIcon />
          </IconButton>
          <Tooltip
            title={
              <Box
                sx={{
                  p: 1.5,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  minWidth: '150px', // Adjust as needed
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                  {user.name}
                </Typography>
                <Typography variant="body2">{user.email}</Typography>
              </Box>
            }
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'rgba(0, 0, 0, 0.8)',
                  color: 'white',
                  '& .MuiTooltip-arrow': {
                    color: 'rgba(0, 0, 0, 0.8)',
                  },
                  marginRight: '20px', // Adjust this value to move away from the right edge
                },
              },
            }}
            placement="bottom-end"
            arrow
          >
            <IconButton onClick={handleAvatarClick}>
              <Avatar src={user.avatar} alt={user.name}>
                {user.name.charAt(0)}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                borderRadius: 2,
                bgcolor: 'grey.200',
                p: 2,
              },
            }}
          >
            <Box
              sx={{
                bgcolor: 'white',
                borderRadius: 2,
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 250,
              }}
            >
              <Typography variant="body2" sx={{ mb: 2, textAlign: 'center' }}>
                {user.email}
              </Typography>
              <Box sx={{ position: 'relative', mb: 2 }}>
                <Avatar src={user.avatar} alt={user.name} sx={{ width: 80, height: 80 }}>
                  {user.name.charAt(0)}
                </Avatar>
                <IconButton
                  size="small"
                  sx={{ position: 'absolute', bottom: 0, right: 0, bgcolor: 'background.paper' }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Box>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Hi, {user.name}!
              </Typography>
              <Button
                variant="outlined"
                fullWidth
                startIcon={<ExitToAppIcon />}
                sx={{
                  textTransform: 'none',
                  color: '#e0680a',
                  borderColor: 'black',
                  '&:hover': {
                    borderColor: 'black',
                  },
                }}
                onClick={handleSignOutClick}
              >
                Sign out
              </Button>
            </Box>
          </Popover>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
        <List sx={{ width: 240, flexShrink: 0, p: 2 }}>
          <StyledListItem
            component={Link}
            to="/dashboard"
            isActive={isPathActive('/dashboard')}
            button
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </StyledListItem>
          <StyledListItem
            button
            component={Link}
            to="/requests"
            isActive={isPathActive('/requests')}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Requests" />
          </StyledListItem>
          <StyledListItem
            button
            component={Link}
            to="/subscriptions"
            isActive={isPathActive('/subscriptions')}
          >
            <ListItemIcon>
              <SubscriptionsIcon />
            </ListItemIcon>
            <ListItemText primary="Subscriptions" />
          </StyledListItem>
          <StyledListItem
            button
            component={Link}
            to="/feedback"
            isActive={isPathActive('/feedback')}
          >
            <ListItemIcon>
              <FeedbackIcon />
            </ListItemIcon>
            <ListItemText primary="Feedback" />
          </StyledListItem>
        </List>
        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            overflow: 'auto',
            bgcolor: '#f0f0f0',
            borderRadius: '16px 0 0 16px',
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
