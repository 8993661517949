import React from 'react';
import { Box, Typography } from '@mui/material';

const ScoreBar = ({ score }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{ flex: 1, mr: 1 }}>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#e0e0e0',
          borderRadius: '3px',
          height: '6px',
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        {Array.from({ length: 5 }).map((_, index) => (
          <Box
            key={index}
            sx={{
              width: '20%',
              height: '100%',
              backgroundColor: score > index * 20 ? '#ff4d00' : 'transparent',
              borderRight: index < 4 ? '1px solid white' : 'none',
              '&:first-of-type': {
                borderTopLeftRadius: '3px',
                borderBottomLeftRadius: '3px',
              },
              '&:last-child': {
                borderTopRightRadius: '3px',
                borderBottomRightRadius: '3px',
              },
            }}
          />
        ))}
      </Box>
    </Box>
    <Typography variant="body2">{score}%</Typography>
  </Box>
);

export default ScoreBar;
