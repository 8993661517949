import React, { useState } from 'react';
import { Box, Typography, Tooltip, IconButton, Select, MenuItem } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const Survey = () => {
  const [selectedQuestion, setSelectedQuestion] = useState('Question 4');

  const surveyData = {
    'Question 1': {
      question: 'How satisfied are you with our product?',
      responses: [
        { name: 'Strongly Agree', value: 40 },
        { name: 'Agree', value: 30 },
        { name: 'Neutral', value: 15 },
        { name: 'Disagree', value: 10 },
        { name: 'Strongly Disagree', value: 5 },
      ],
    },
    'Question 2': {
      question: 'How likely are you to recommend our product to others?',
      responses: [
        { name: 'Strongly Agree', value: 45 },
        { name: 'Agree', value: 35 },
        { name: 'Neutral', value: 10 },
        { name: 'Disagree', value: 7 },
        { name: 'Strongly Disagree', value: 3 },
      ],
    },
    'Question 3': {
      question: 'How easy was it to use our product?',
      responses: [
        { name: 'Strongly Agree', value: 20 },
        { name: 'Agree', value: 40 },
        { name: 'Neutral', value: 25 },
        { name: 'Disagree', value: 10 },
        { name: 'Strongly Disagree', value: 5 },
      ],
    },
    'Question 4': {
      question: 'I found the various functions in the product were well integrated.',
      responses: [
        { name: 'Strongly Agree', value: 80 },
        { name: 'Agree', value: 60 },
        { name: 'Neutral', value: 20 },
        { name: 'Disagree', value: 16 },
        { name: 'Strongly Disagree', value: 4 },
      ],
    },
  };

  const handleQuestionChange = (event) => {
    setSelectedQuestion(event.target.value);
  };

  const totalResponses = surveyData[selectedQuestion].responses.reduce(
    (sum, item) => sum + item.value,
    0,
  );

  const agreedPercentage = (
    ((surveyData[selectedQuestion].responses[0].value +
      surveyData[selectedQuestion].responses[1].value) /
      totalResponses) *
    100
  ).toFixed(0);

  const getColorForValue = (value) => {
    if (value >= 80) return '#4CAF50'; // Dark Green
    if (value >= 60) return '#8BC34A'; // Light Green
    if (value >= 40) return '#FFC107'; // Yellow
    if (value >= 20) return '#FF9800'; // Orange
    return '#F44336'; // Red
  };

  return (
    <Box sx={{ p: 1.5, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2" component="h2" sx={{ fontWeight: 'medium' }}>
            Survey
          </Typography>
          <Tooltip title="Results from our latest user survey">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Select
          value={selectedQuestion}
          onChange={handleQuestionChange}
          size="small"
          sx={{ fontSize: '12px', minWidth: '80px', height: '30px' }}
        >
          {Object.keys(surveyData).map((question) => (
            <MenuItem key={question} value={question}>
              {question}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box
        sx={{
          mb: 2,
          pb: 1,
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '0.9rem',
          width: '100%',
          margin: '0 auto',
          borderBottom: '1px solid #ccc',
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            pr: 2,
            borderRight: '1px solid #e0e0e0',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
            <strong>{selectedQuestion}:</strong> {surveyData[selectedQuestion].question}
          </Typography>
        </Box>
        <Box
          sx={{
            pl: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '80px',
            maxWidth: '100px',
          }}
        >
          <Typography color="black" sx={{ fontSize: '1.8rem', lineHeight: 1 }}>
            {agreedPercentage}%
          </Typography>
          <Typography color="black" sx={{ fontSize: '1rem', lineHeight: 1 }}>
            Agreed
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginTop: 3,
        }}
      >
        {surveyData[selectedQuestion].responses.map((response, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Box sx={{ flexGrow: 1, height: 30, mr: 2, position: 'relative' }}>
              <Box
                sx={{ width: '100%', height: '100%', backgroundColor: '#f0f0f0', borderRadius: 1 }}
              />
              <Box
                sx={{
                  width: `${response.value}%`,
                  height: '100%',
                  backgroundColor: getColorForValue(response.value),
                  borderRadius: 1,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingRight: 1,
                  transition: 'width 0.5s ease-in-out', // Add smooth transition
                }}
              >
                <Typography sx={{ color: 'white', fontSize: 12, fontWeight: 'bold' }}>
                  {response.value}
                </Typography>
              </Box>
            </Box>
            <Typography sx={{ minWidth: 130, textAlign: 'left', fontSize: 14 }}>
              {response.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Survey;
