import React, { useState } from 'react';
import { Button, TextField, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { textFieldStyle } from './AuthStyles';

const SignUp = () => {
  const [formData, setFormData] = useState({ username: '', email: '', password: '' });
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Mock signup logic
    console.log('Sign Up', formData);
    navigate('/login');
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 400, margin: 'auto', mt: 8 }}>
      <Typography color="#e0680a" fontWeight="500" variant="h4" align="center" gutterBottom>
        Sign Up
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Username"
          value={formData.username}
          onChange={(e) => setFormData({ ...formData, username: e.target.value })}
          sx={textFieldStyle}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          sx={textFieldStyle}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Password"
          type="password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          sx={textFieldStyle}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ bgcolor: '#e0680a', mt: 2, mb: 2 }}
        >
          Sign Up
        </Button>
      </form>
    </Box>
  );
};

export default SignUp;
