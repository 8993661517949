import React from 'react';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';

const ROI = () => {
  const data = [
    { name: 'Investment', value: 100000 },
    { name: 'Return', value: 170000 },
  ];

  const colors = {
    Investment: '#FFC107',
    Return: '#4CAF50',
  };

  // Calculate ROI
  const investment = data[0].value;
  const returnValue = data[1].value;
  const roi = ((returnValue - investment) / investment) * 100;

  return (
    <Box sx={{ p: 1.5, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant="subtitle2" component="h2" sx={{ fontWeight: 'medium' }}>
          ROI
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{ flexGrow: 1, textAlign: 'center', fontWeight: 'medium' }}
        >
          ROI: {roi.toFixed(2)}%
        </Typography>
        <Tooltip title="Return on Investment">
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <Box sx={{ flexGrow: 1, height: 300 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            barSize={40}
            barGap={40}
          >
            <XAxis dataKey="name" tickLine={false} />
            <YAxis
              ticks={[0, 50000, 100000, 150000, 200000, 250000]}
              tickFormatter={(value) => `${value / 1000}k`}
              tickLine={false}
            />
            <RechartsTooltip formatter={(value) => `$${value.toLocaleString()}`} />
            <Bar dataKey="value">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[entry.name]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default ROI;
