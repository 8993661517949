import React from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const EditButton = ({ onClick }) => {
  return (
    <Button
      startIcon={<EditIcon fontSize="small" />}
      onClick={onClick}
      sx={{
        mr: 1,
        color: 'warning.main',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        textTransform: 'none',
        padding: '1px 1px',
        '& .MuiButton-startIcon': {
          marginRight: '4px',
          marginLeft: '-2px',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '16px',
        },
        typography: 'body2',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
      }}
    >
      Edit
    </Button>
  );
};

export default EditButton;
