import React, { useState } from 'react';
import { Box, Typography, Tooltip, IconButton, Select, MenuItem } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from 'recharts';

const UserChurn = () => {
  const [timePeriod, setTimePeriod] = useState('Week');

  // Mock data - in a real application, this would come from an API or props
  const data = {
    Week: [
      { name: 'Mon', churn: 20 },
      { name: 'Tue', churn: 40 },
      { name: 'Wed', churn: 40 },
      { name: 'Thu', churn: 20 },
      { name: 'Fri', churn: 60 },
      { name: 'Sat', churn: 60 },
      { name: 'Sun', churn: 80 },
    ],
    Month: [
      { name: 'Week 1', churn: 30 },
      { name: 'Week 2', churn: 45 },
      { name: 'Week 3', churn: 55 },
      { name: 'Week 4', churn: 40 },
    ],
    Quarter: [
      { name: 'Month 1', churn: 35 },
      { name: 'Month 2', churn: 50 },
      { name: 'Month 3', churn: 45 },
    ],
  };

  const handlePeriodChange = (event) => {
    setTimePeriod(event.target.value);
  };

  return (
    <Box sx={{ p: 1.5, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2" component="h2" sx={{ fontWeight: 'medium' }}>
            User Churn
          </Typography>
          <Tooltip title="Percentage of users who stop using the product over time">
            <IconButton size="small">
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Select
          value={timePeriod}
          onChange={handlePeriodChange}
          size="small"
          sx={{ fontSize: '12px', minWidth: '80px', height: '30px' }} // Adjust these values as needed
        >
          <MenuItem value="Week">Week</MenuItem>
          <MenuItem value="Month">Month</MenuItem>
          <MenuItem value="Quarter">Quarter</MenuItem>
        </Select>
      </Box>

      <Box sx={{ flexGrow: 1, height: 200 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data[timePeriod]} margin={{ top: 5, right: 30, left: -15, bottom: 5 }}>
            <CartesianGrid strokeDasharray="1" horizontal={true} vertical={false} />
            <XAxis
              dataKey="name"
              tick={{ fontSize: 12 }} // Adjust the font size
              tickLine={false} // Remove the tick lines
            />
            <YAxis
              unit="%"
              tick={{ fontSize: 12 }} // Adjust the font size
              tickLine={false} // Remove the tick lines
            />
            <RechartsTooltip formatter={(value) => `${value}%`} />
            <Line
              type="line"
              dataKey="churn"
              stroke="#87CEEB"
              activeDot={{ r: 8 }}
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default UserChurn;
