import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import Layout from './components/Layout';
import UserDetails from './components/UserDetails/UserDetails';
import Requests from './components/Requests/Requests';
import Dashboard from './components/Dashboard/Dashboard';
import Subscriptions from './components/Subscriptions';
import Feedback from './components/Feedback';
import Login from './components/Auth/Login'; // New Login Component
import SignUp from './components/Auth/SignUp'; // New SignUp Component
import ForgotPassword from './components/Auth/ForgotPassword'; // New ForgotPassword Component
import theme from './theme';
import { useAuth } from './contexts/AuthContext';

const App = () => {
  const { isAuthenticated, signOut } = useAuth(); // Use auth context

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        {!isAuthenticated ? (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<Navigate replace to="/login" />} />
          </Routes>
        ) : (
          <Layout onSignOut={signOut}>
            {' '}
            {/* Pass signOut from context */}
            <Routes>
              <Route path="/" element={<Navigate replace to="/dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route path="/requests" element={<Requests />} />
              <Route path="/user/:userId" element={<UserDetails />} />
              <Route path="/feedback" element={<Feedback />} />
              {/* Optionally, handle unmatched routes within authenticated area */}
              <Route path="*" element={<Navigate replace to="/dashboard" />} />
            </Routes>
          </Layout>
        )}
      </Router>
    </ThemeProvider>
  );
};

export default App;
