import React from 'react';
import { Box, Typography, List, ListItemButton, ListItemText } from '@mui/material';
import { NumberedButton, StyledListItemForQuestions } from '../../../styles/StyledComponents';

const QuestionList = ({ questions, selectedQuestionIndex, onQuestionChange }) => {
  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
        Question List
      </Typography>
      <List
        sx={{
          overflowY: 'auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          '& > li': { marginBottom: '1vh' },
        }}
      >
        {questions.map((question, index) => (
          <StyledListItemForQuestions key={index}>
            <ListItemButton
              onClick={() => onQuestionChange(index)}
              sx={{
                borderRadius: 1,
                padding: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <NumberedButton
                active={selectedQuestionIndex === index}
                onClick={(e) => {
                  e.stopPropagation();
                  onQuestionChange(index);
                }}
              >
                {index + 1}
              </NumberedButton>
              <ListItemText
                primary={question}
                primaryTypographyProps={{
                  fontWeight: selectedQuestionIndex === index ? 'bold' : 'normal',
                  color: selectedQuestionIndex === index ? 'text.primary' : 'text.secondary',
                  ml: 2,
                }}
              />
            </ListItemButton>
          </StyledListItemForQuestions>
        ))}
      </List>
    </Box>
  );
};

export default QuestionList;
