import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, InputAdornment } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

const SortField = ({ sortOrder, handleSortChange }) => (
  <FormControl fullWidth variant="outlined">
    <InputLabel>Sort By</InputLabel>
    <Select
      value={sortOrder}
      onChange={handleSortChange}
      label="Sort By"
      startAdornment={
        <InputAdornment position="start">
          <SortIcon />
        </InputAdornment>
      }
      sx={{ borderRadius: '20px' }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      <MenuItem value="email_asc">Email (A-Z)</MenuItem>
      <MenuItem value="email_desc">Email (Z-A)</MenuItem>
      <MenuItem value="attempt_asc">Attempt (Low to High)</MenuItem>
      <MenuItem value="attempt_desc">Attempt (High to Low)</MenuItem>
      <MenuItem value="date_asc">Date (Oldest First)</MenuItem>
      <MenuItem value="date_desc">Date (Newest First)</MenuItem>
    </Select>
  </FormControl>
);

export default SortField;
