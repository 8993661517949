import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  Checkbox,
} from '@mui/material';
import {
  StyledTableCellBody,
  StyledTableCell,
  DetailButton,
} from '../../../styles/StyledComponents';
import ScoreBar from './ScoreBar';

const RequestsTable = ({
  paginatedUsers,
  selected,
  handleSelectAllClick,
  handleClick,
  isSelected,
  handleDetailsClick,
}) => {
  return (
    <TableContainer component={Paper} sx={{ mb: 3 }}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell padding="checkbox" style={{ width: '5%' }}>
              <Checkbox
                color="primary"
                indeterminate={selected.length > 0 && selected.length < paginatedUsers.length}
                checked={paginatedUsers.length > 0 && selected.length === paginatedUsers.length}
                onChange={handleSelectAllClick}
                inputProps={{
                  'aria-label': 'select all users',
                }}
              />
            </StyledTableCell>
            <StyledTableCell className="column-email">Email</StyledTableCell>
            <StyledTableCell className="column-date">Date</StyledTableCell>
            <StyledTableCell className="column-attempt">Attempt</StyledTableCell>
            <StyledTableCell className="column-score">Score</StyledTableCell>
            <StyledTableCell className="column-action" align="center">
              Action
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedUsers.map((user) => {
            const isItemSelected = isSelected(user.id);
            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, user.id)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={user.id}
                selected={isItemSelected}
              >
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      'aria-labelledby': `enhanced-table-checkbox-${user.id}`,
                    }}
                  />
                </StyledTableCell>
                <StyledTableCellBody>{user.email}</StyledTableCellBody>
                <StyledTableCellBody>{user.date}</StyledTableCellBody>
                <StyledTableCellBody>{user.attempt}</StyledTableCellBody>
                <StyledTableCellBody>
                  <ScoreBar score={user.score} />
                </StyledTableCellBody>
                <StyledTableCellBody align="center">
                  <DetailButton
                    variant="outlined"
                    size="small"
                    onClick={() => handleDetailsClick(user.id, user.email)}
                  >
                    Details
                  </DetailButton>
                </StyledTableCellBody>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RequestsTable;
