import React from 'react';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const CustomerAcquisitionCost = () => {
  const data = [
    { quarter: '', NewUsers: 3900, RepeatUsers: 1800, CAC: 4.5 },
    { quarter: '', NewUsers: 8500, RepeatUsers: 4300, CAC: 10.6 },
    { quarter: '', NewUsers: 6800, RepeatUsers: 4300, CAC: 10.6 },
    { quarter: '', NewUsers: 8500, RepeatUsers: 6000, CAC: 15 },
    { quarter: '', NewUsers: 6800, RepeatUsers: 3000, CAC: 7.5 },
  ];

  return (
    <Box sx={{ p: 1.5, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant="subtitle2" component="h2" sx={{ fontWeight: 'medium' }}>
          Customer Acquisition Cost
        </Typography>
        <Tooltip title="Average cost of acquiring a new customer">
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <Box sx={{ flexGrow: 1, height: 200 }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            barSize={20}
            barGap={0}
            barCategoryGap="35%"
          >
            <XAxis dataKey="quarter" tick={{ fontSize: 10 }} tickLine={false} />
            <YAxis
              yAxisId="left"
              tick={{ fontSize: 10 }}
              label={{
                value: 'Number of Customers',
                angle: -90,
                position: 'insideLeft',
                style: { fontSize: 15, textAnchor: 'middle' },
              }}
              domain={[0, 10000]}
              ticks={[0, 2000, 4000, 6000, 8000, 10000]}
              tickFormatter={(value) => `${value / 1000}k`}
              tickLine={false}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              tick={{ fontSize: 10 }}
              label={{
                value: 'Customer Acquisition Cost',
                angle: 90,
                position: 'insideRight',
                style: { fontSize: 15, textAnchor: 'middle' },
              }}
              domain={[0, 25]}
              ticks={[0, 5, 10, 15, 20, 25]}
              tickLine={false}
            />
            <RechartsTooltip />
            <Legend
              wrapperStyle={{ fontSize: 13 }}
              formatter={(value) => <span style={{ color: 'black' }}>{value}</span>}
            />
            <Bar yAxisId="left" dataKey="NewUsers" name="New Users" fill="#4CAF50" /> {/* Green */}
            <Bar yAxisId="left" dataKey="RepeatUsers" name="Repeat Users" fill="#FFC107" />{' '}
            {/* Yellow */}
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="CAC"
              name="CAC"
              stroke="#000000"
              strokeWidth={2}
              dot={{ fill: '#000000', r: 4 }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default CustomerAcquisitionCost;
