import React from 'react';
import { Typography } from '@mui/material';
import PageContainer from './PageContainer';

const Subscriptions = () => {
  return (
    <PageContainer title="Subscriptions">
      <Typography variant="body1">
        This is the subscriptions page. You can display more subscription based information here.
      </Typography>
    </PageContainer>
  );
};

export default Subscriptions;
