import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  Box,
} from '@mui/material';

const EditScore = ({ open, onClose, score, description, onSave }) => {
  const [editedScore, setEditedScore] = useState(score);
  const [editedDescription, setEditedDescription] = useState(description);

  const handleScoreChange = (value) => {
    setEditedScore(Math.max(0, Math.min(100, Number(value))));
  };

  const handleDescriptionChange = (event) => {
    setEditedDescription(event.target.value);
  };

  const handleSave = () => {
    onSave(editedScore, editedDescription);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle>Edit Score</DialogTitle>
      <DialogContent>
        <Box sx={{ my: 2 }}>
          <TextField
            label="Score"
            value={editedScore}
            onChange={(e) => handleScoreChange(e.target.value)}
            fullWidth
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span style={{ color: '#e0680a' }}>%</span>
                </InputAdornment>
              ),
            }}
            inputProps={{
              min: 0,
              max: 100,
            }}
          />
        </Box>
        <Box sx={{ my: 2 }}>
          <TextField
            label="Description"
            value={editedDescription}
            onChange={handleDescriptionChange}
            fullWidth
            multiline
            rows={4}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <Button
          onClick={onClose}
          sx={{
            color: 'warning.main',
            borderColor: 'warning.main',
            '&:hover': {
              backgroundColor: 'warning.light',
              borderColor: 'warning.main',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{
            backgroundColor: 'warning.main',
            color: 'white',
            '&:hover': {
              backgroundColor: 'warning.dark',
            },
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditScore.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  score: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditScore;
