import React from 'react';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const DownloadsVsActivations = () => {
  const data = [
    { quarter: 'Mon', Downloads: 3900, Activations: 1800 },
    { quarter: 'Tue', Downloads: 8500, Activations: 4300 },
    { quarter: 'Wed', Downloads: 6800, Activations: 4300 },
    { quarter: 'Thu', Downloads: 8500, Activations: 6000 },
    { quarter: 'Fri', Downloads: 6800, Activations: 3000 },
    { quarter: 'Sat', Downloads: 6800, Activations: 3000 },
    { quarter: 'Sun', Downloads: 6800, Activations: 3000 },
  ];

  return (
    <Box sx={{ p: 1.5, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant="subtitle2" component="h2" sx={{ fontWeight: 'medium' }}>
          Downloads Vs Activations
        </Typography>
        <Tooltip title="Comparison of app downloads to actual activations">
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <Box sx={{ flexGrow: 1, height: 200 }}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={data}
            margin={{ top: 10, right: 10, left: -20, bottom: 0 }}
            barSize={15}
            barGap={0}
            barCategoryGap="35%"
          >
            <XAxis dataKey="quarter" tick={{ fontSize: 10 }} tickLine={false} />
            <YAxis
              yAxisId="left"
              tick={{ fontSize: 10 }}
              domain={[0, 10000]}
              ticks={[0, 2000, 4000, 6000, 8000, 10000]}
              tickFormatter={(value) => `${value / 1000}k`}
              tickLine={false}
            />
            <RechartsTooltip />
            <Legend
              wrapperStyle={{ fontSize: 13 }}
              formatter={(value) => <span style={{ color: 'black' }}>{value}</span>}
            />
            <Bar yAxisId="left" dataKey="Downloads" name="Downloads" fill="#4CAF50" /> {/* Green */}
            <Bar yAxisId="left" dataKey="Activations" name="Activations" fill="#87CEEB" />{' '}
            {/* Light Blue */}
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default DownloadsVsActivations;
