import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import EditButton from '../Elements/EditButton';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditScore from '../Elements/EditScore';

const ScoreSection = ({
  score,
  description,
  handleEditClick,
  onEditClose,
  onEditSave,
  isEditDialogOpen,
  onAccept,
  onReject,
}) => {
  return (
    <Box
      sx={{
        height: '100%',
        bgcolor: 'background.paper',
        borderRadius: 2,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="subtitle1" fontWeight="bold">
          Score
        </Typography>
        <EditButton onClick={handleEditClick} />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {score !== undefined && description ? (
          <>
            <Box>
              <Typography variant="h2" sx={{ textAlign: 'left', fontWeight: '450', mb: 2 }}>
                {score}%
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {description}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
              <Button
                variant="contained"
                color="error"
                startIcon={<CloseIcon />}
                onClick={onReject}
                sx={{
                  minWidth: 300,
                  height: 48,
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                color="success"
                startIcon={<CheckIcon />}
                onClick={onAccept}
                sx={{
                  minWidth: 300,
                  height: 48,
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
              >
                Accept
              </Button>
            </Box>
          </>
        ) : (
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ textAlign: 'center', my: 'auto' }}
          >
            No score data available
          </Typography>
        )}
      </Box>
      <EditScore
        open={isEditDialogOpen}
        onClose={onEditClose}
        score={score}
        description={description}
        onSave={onEditSave}
      />
    </Box>
  );
};

export default ScoreSection;
