import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
  Box,
} from '@mui/material';

const EditDonuts = ({ open, onClose, metrics, onSave }) => {
  const [editedMetrics, setEditedMetrics] = useState(metrics);

  const handleChange = (name, value) => {
    setEditedMetrics((prev) =>
      prev.map((metric) =>
        metric.name === name
          ? { ...metric, value: Math.max(0, Math.min(100, Number(value))) }
          : metric,
      ),
    );
  };

  const handleSave = () => {
    onSave(editedMetrics);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '16px', // Increase this value for more roundness
        },
      }}
    >
      <DialogTitle>Edit Report Metrics</DialogTitle>
      <DialogContent>
        {editedMetrics.map((metric) => (
          <Box key={metric.name} sx={{ my: 2 }}>
            <TextField
              label={metric.name}
              value={metric.value}
              onChange={(e) => handleChange(metric.name, e.target.value)}
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span style={{ color: '#e0680a' }}>%</span>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                min: 0,
                max: 100,
              }}
            />
          </Box>
        ))}
      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <Button
          onClick={onClose}
          sx={{
            color: 'warning.main',
            borderColor: 'warning.main',
            '&:hover': {
              backgroundColor: 'warning.light',
              borderColor: 'warning.main',
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{
            backgroundColor: 'warning.main',
            color: 'white',
            '&:hover': {
              backgroundColor: 'warning.dark',
            },
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditDonuts.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditDonuts;
