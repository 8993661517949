import { formatDate } from './dateUtils';

export const generateMockUsers = (count = 100) => {
  return Array.from({ length: count }, (_, index) => ({
    id: index + 1,
    email: `user${index + 1}@example.com`,
    date: formatDate(new Date(Date.now() - Math.floor(Math.random() * 10000000000))),
    attempt: Math.floor(Math.random() * 10) + 1,
    score: Math.floor(Math.random() * 101),
  }));
};
