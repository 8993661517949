import { useState, useEffect } from 'react';
import { generateMockUsers } from '../utils/mockData';

const useRequestsData = () => {
  const [users] = useState(generateMockUsers());
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(6);
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [filter, setFilter] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(users);

  useEffect(() => {
    const applyFilter = (users) => {
      if (!filter) return users;

      const oneWeekAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

      switch (filter) {
        case 'recentDate':
          return users.filter((user) => new Date(user.date) >= oneWeekAgo);
        case 'highScore':
          return users.filter((user) => user.score >= 80);
        case 'lowScore':
          return users.filter((user) => user.score < 40);
        case 'multipleAttempts':
          return users.filter((user) => user.attempt > 1);
        default:
          return users;
      }
    };

    let result = users;
    if (search.length >= 3) {
      result = result.filter((user) => user.email.toLowerCase().includes(search.toLowerCase()));
    }
    result = applyFilter(result);
    setFilteredUsers(result);
  }, [search, filter, users]);

  const sortedUsers = [...filteredUsers].sort((a, b) => {
    switch (sortOrder) {
      case 'email_asc':
        return a.email.localeCompare(b.email);
      case 'email_desc':
        return b.email.localeCompare(a.email);
      case 'attempt_asc':
        return a.attempt - b.attempt;
      case 'attempt_desc':
        return b.attempt - a.attempt;
      case 'date_asc':
        return new Date(a.date) - new Date(b.date);
      case 'date_desc':
        return new Date(b.date) - new Date(a.date);
      default:
        return 0;
    }
  });

  const paginatedUsers = sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const pageCount = Math.ceil(filteredUsers.length / rowsPerPage);

  return {
    paginatedUsers,
    pageCount,
    page,
    setPage,
    search,
    setSearch,
    sortOrder,
    setSortOrder,
    filter,
    setFilter,
  };
};

export default useRequestsData;
