import React from 'react';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
} from 'recharts';

const ConversionRate = () => {
  const data = [
    { name: 'PC', value: 48 },
    { name: 'Mobile', value: 72 },
  ];

  const colors = {
    PC: '#FFA500',
    Mobile: '#87CEEB',
  };

  const CustomLabel = (props) => {
    const { x, y, width, value, name } = props;
    return (
      <g>
        <text
          x={x + width / 2}
          y={y - 30}
          fill="#000000"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {name} Users
        </text>
        <text
          x={x + width / 2}
          y={y - 10}
          fill="#000000"
          textAnchor="middle"
          dominantBaseline="middle"
          fontWeight="bold"
        >
          {value}%
        </text>
      </g>
    );
  };

  return (
    <Box sx={{ p: 1.5, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant="subtitle2" component="h2" sx={{ fontWeight: 'medium' }}>
          Conversion Rate
        </Typography>
        <Tooltip title="Percentage of visitors who take a desired action">
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <Box sx={{ flexGrow: 1, height: 200 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{ top: 40, right: 30, left: 20, bottom: 5 }}
            barSize={40}
            barGap={40}
          >
            <XAxis dataKey="name" tickLine={false} />
            <YAxis
              ticks={[0, 20, 40, 60, 80, 100]}
              domain={[0, 100]}
              tickFormatter={(value) => `${value}%`}
              tickLine={false}
            />
            <RechartsTooltip formatter={(value) => `${value}%`} />
            <Bar dataKey="value">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[entry.name]} />
              ))}
              <LabelList content={<CustomLabel />} position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default ConversionRate;
