import React from 'react';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { UpArrowIcon, DownArrowIcon } from '../Styles/arrows';

const NewUsers = () => {
  // Mock data - in a real application, this would come from an API or props
  const newUsers = 123;
  const percentageChange = 25;
  const comparisonValue = 123;
  const isIncrease = percentageChange > 0;

  return (
    <Box sx={{ p: 1.5, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant="subtitle1" component="h2" sx={{ fontWeight: 'medium' }}>
          New Users
        </Typography>
        <Tooltip title="Number of new user registrations in the selected period">
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          <Typography variant="h4" component="span">
            {newUsers.toLocaleString()}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
            {isIncrease ? (
              <UpArrowIcon sx={{ fontSize: 20, mr: 0.5 }} />
            ) : (
              <DownArrowIcon sx={{ fontSize: 20, mr: 0.5 }} />
            )}
            <Typography
              variant="subtitle1"
              component="span"
              sx={{ color: isIncrease ? '#34a853' : '#ea4335', fontWeight: 'bold' }}
            >
              {isIncrease ? '+' : '-'}
              {Math.abs(percentageChange)}%
            </Typography>
          </Box>
        </Box>

        <Typography variant="body2" sx={{ color: isIncrease ? '#34a853' : '#ea4335' }}>
          {isIncrease ? '+' : '-'}
          {Math.abs(comparisonValue).toLocaleString()} Compared to last week
        </Typography>
      </Box>
    </Box>
  );
};

export default NewUsers;
