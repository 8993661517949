import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, InputAdornment } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

const FilterField = ({ filter, handleFilterChange }) => (
  <FormControl fullWidth variant="outlined">
    <InputLabel>Filter By</InputLabel>
    <Select
      value={filter}
      onChange={handleFilterChange}
      label="Filter By"
      startAdornment={
        <InputAdornment position="start">
          <FilterListIcon />
        </InputAdornment>
      }
      sx={{ borderRadius: '20px' }}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      <MenuItem value="recentDate">Last 7 days</MenuItem>
      <MenuItem value="highScore">High Score (more than 80%)</MenuItem>
      <MenuItem value="lowScore">Low Score (lesser than 40%)</MenuItem>
      <MenuItem value="multipleAttempts">Multiple Attempts</MenuItem>
    </Select>
  </FormControl>
);

export default FilterField;
