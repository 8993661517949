// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Update import
import App from './App';
import './index.css';
import { AuthProvider } from './contexts/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root')); // Create root
root.render(
  <React.StrictMode>
    <AuthProvider>
      {' '}
      {/* Wrap with AuthProvider */}
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
