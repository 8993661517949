import React, { useState } from 'react';
import PageContainer from '../PageContainer';
import { Box, Grid } from '@mui/material';
import { StyledTabs, StyledTab, ContentBox } from '../../styles/StyledComponents';
import RequestsTable from './Elements/RequestsTable';
import PaginationButtons from './Elements/PaginationButtons';
import useRequestsData from '../../hooks/useRequestsData';
import { useRequestsHandlers } from '../Handlers';
import SearchField from './Elements/Fields/Search';
import FilterField from './Elements/Fields/Filter';
import SortField from './Elements/Fields/Sort';

const Requests = () => {
  const [tabValue, setTabValue] = useState(0);
  const [selected, setSelected] = useState([]);
  const {
    paginatedUsers,
    pageCount,
    page,
    setPage,
    search,
    setSearch,
    sortOrder,
    setSortOrder,
    filter,
    setFilter,
  } = useRequestsData();

  const handlers = useRequestsHandlers(
    setTabValue,
    setPage,
    setSearch,
    setFilter,
    setSortOrder,
    paginatedUsers,
    selected,
    setSelected,
  );

  return (
    <PageContainer title="Requests">
      <ContentBox>
        <StyledTabs value={tabValue} onChange={handlers.handleChangeTab}>
          <StyledTab label="New Users" />
          <StyledTab label="Brand Ambassador" />
        </StyledTabs>
        {tabValue === 0 && (
          <>
            <Box sx={{ mt: 3, mb: 3 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <SearchField search={search} handleSearchChange={handlers.handleSearchChange} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FilterField filter={filter} handleFilterChange={handlers.handleFilterChange} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <SortField sortOrder={sortOrder} handleSortChange={handlers.handleSortChange} />
                </Grid>
              </Grid>
            </Box>

            <RequestsTable
              paginatedUsers={paginatedUsers}
              selected={selected}
              handleSelectAllClick={handlers.handleSelectAllClick}
              handleClick={handlers.handleClick}
              isSelected={handlers.isSelected}
              handleDetailsClick={handlers.handleDetailsClick}
            />

            <PaginationButtons
              page={page}
              pageCount={pageCount}
              handleChangePage={handlers.handleChangePage}
            />
          </>
        )}
      </ContentBox>
    </PageContainer>
  );
};

export default Requests;
