import React, { useState, useEffect, useCallback } from 'react';
import { Box, Divider, Typography, CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import PageContainer from '../PageContainer';
import VideoSection from './Sections/Video';
import QuestionList from './Sections/QuestionList';
import ReportSection from './Sections/Report';
import ScoreSection from './Sections/Score';
import Approve from './Elements/Approve';
import Reject from './Elements/Reject';
import { useUserDetailsHandlers } from '../Handlers';

const UserDetails = () => {
  const location = useLocation();
  const { email, userDetails: initialUserDetails } = location.state || {};

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [userDetails, setUserDetails] = useState(initialUserDetails);
  const [currentQuestionData, setCurrentQuestionData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handlers = useUserDetailsHandlers(
    setSelectedQuestionIndex,
    userDetails,
    setUserDetails,
    email,
  );

  useEffect(() => {
    if (userDetails && userDetails.length > 0) {
      setCurrentQuestionData(userDetails[selectedQuestionIndex]);
      setIsLoading(false);
    } else {
      setError('User details not available or in unexpected format');
      setIsLoading(false);
    }
  }, [selectedQuestionIndex, userDetails]);

  const handleUpdateReport = useCallback(
    (updatedReport) => {
      setUserDetails((prevUserDetails) => {
        const newUserDetails = [...prevUserDetails];
        newUserDetails[selectedQuestionIndex] = {
          ...newUserDetails[selectedQuestionIndex],
          report: updatedReport,
        };
        return newUserDetails;
      });
    },
    [selectedQuestionIndex],
  );

  const handleUpdateScore = useCallback(
    (newScore, newDescription) => {
      setUserDetails((prevUserDetails) => {
        const newUserDetails = [...prevUserDetails];
        newUserDetails[selectedQuestionIndex] = {
          ...newUserDetails[selectedQuestionIndex],
          aggregateScore: newScore,
          aggregateDescription: newDescription,
        };
        return newUserDetails;
      });
    },
    [selectedQuestionIndex],
  );

  if (isLoading) {
    return (
      <PageContainer title="Loading...">
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer title="Error">
        <Typography color="error">{error}</Typography>
      </PageContainer>
    );
  }

  return (
    <PageContainer title={email || 'User Details'}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '1fr 1fr',
          gap: 3,
          height: '100%',
          position: 'relative',
        }}
      >
        <VideoSection videoUrl={currentQuestionData?.video} />
        <QuestionList
          questions={userDetails.map((detail) => detail.question)}
          selectedQuestionIndex={selectedQuestionIndex}
          onQuestionChange={handlers.handleQuestionChange}
        />
        <ReportSection report={currentQuestionData?.report} onUpdateReport={handleUpdateReport} />
        <ScoreSection
          score={currentQuestionData?.aggregateScore}
          description={currentQuestionData?.aggregateDescription}
          handleEditClick={handlers.handleEditScore}
          onEditClose={handlers.handleEditScoreClose}
          onEditSave={handleUpdateScore}
          isEditDialogOpen={handlers.isEditScoreDialogOpen}
          onAccept={handlers.handleAccept}
          onReject={handlers.handleRejectOpen}
        />
        <Divider
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: '50%',
            borderColor: 'rgba(0, 0, 0, 0.2)',
          }}
        />
        <Divider
          orientation="vertical"
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: '50%',
            borderColor: 'rgba(0, 0, 0, 0.2)',
          }}
        />
      </Box>
      <Approve
        open={handlers.isApprovalDialogOpen}
        onClose={handlers.handleApprovalDialogClose}
        userEmail={handlers.email}
      />
      <Reject
        open={handlers.isRejectDialogOpen}
        onClose={handlers.handleRejectClose}
        onReject={handlers.handleReject}
        onReasonChange={handlers.handleReasonChange}
        onOtherReasonChange={handlers.handleOtherReasonChange}
        onRejectConfirm={handlers.handleRejectConfirm}
        reason={handlers.rejectionReason}
        otherReason={handlers.otherRejectionReason}
        step={handlers.rejectionStep}
        userEmail={handlers.email}
      />
    </PageContainer>
  );
};

export default UserDetails;
