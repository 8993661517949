import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Box, Button, styled } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import 'react-datepicker/dist/react-datepicker.css';

const StyledBox = styled(Box)({
  '& .react-datepicker-wrapper': {
    width: '100%',
  },
});

const OrangeButton = styled(Button)(() => ({
  backgroundColor: '#e0680a',
  color: 'white',
  '&:hover': {
    backgroundColor: '#c25e09',
  },
}));

const CalendarWidget = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  return (
    <StyledBox>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        customInput={
          <OrangeButton variant="contained" startIcon={<CalendarTodayIcon />}>
            {startDate && endDate
              ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
              : 'Select Date Range'}
          </OrangeButton>
        }
        isClearable={true}
      />
      <style>
        {`
          .react-datepicker {
            font-family: Arial, sans-serif;
            border-radius: 0;
            border: 1px solid #e0680a;
          }
          .react-datepicker__header {
            background-color: #e0680a;
            color: white;
            border-bottom: none;
          }
          .react-datepicker__current-month,
          .react-datepicker__day-name {
            color: white;
          }
          .react-datepicker__day--selected,
          .react-datepicker__day--in-selecting-range,
          .react-datepicker__day--in-range {
            background-color: #e0680a;
            color: white;
          }
          .react-datepicker__day--keyboard-selected {
            background-color: #c25e09;
            color: white;
          }
          .react-datepicker__day:hover {
            background-color: #c25e09;
          }
          .react-datepicker__close-icon::after {
            background-color: #808080;
          }
          .react-datepicker__close-icon::hover::after {
            background-color: #606060;
          }
        `}
      </style>
    </StyledBox>
  );
};

export default CalendarWidget;
