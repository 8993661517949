import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import adhdVideo from '../assets/adhd.mp4';

export const useRequestsHandlers = (
  setTabValue,
  setPage,
  setSearch,
  setFilter,
  setSortOrder,
  paginatedUsers,
  selected,
  setSelected,
) => {
  const navigate = useNavigate();

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(0);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = paginatedUsers.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDetailsClick = async (id, email) => {
    // In a real application, this would be an API call
    const mockUserDetails = [
      {
        question: 'Do you have ADHD?',
        video: adhdVideo,
        report: {
          RelevanceOfResponse: 70,
          QualityOfContent: 60,
          EngagementLevel: 80,
        },
        aggregateScore: 75,
        aggregateDescription: 'The user showed a good understanding of ADHD.',
      },
      {
        question: 'Have you been diagnosed with ADHD?',
        video: adhdVideo,
        report: {
          RelevanceOfResponse: 80,
          QualityOfContent: 75,
          EngagementLevel: 85,
        },
        aggregateScore: 80,
        aggregateDescription: 'The user provided a clear account of their ADHD diagnosis.',
      },
      {
        question: 'What would a safe space mean to you?',
        video: adhdVideo,
        report: {
          RelevanceOfResponse: 65,
          QualityOfContent: 70,
          EngagementLevel: 75,
        },
        aggregateScore: 70,
        aggregateDescription:
          'The user expressed a good understanding of what constitutes a safe space.',
      },
      {
        question: 'How do you intend to support the community and embrace the community?',
        video: adhdVideo,
        report: {
          RelevanceOfResponse: 75,
          QualityOfContent: 15,
          EngagementLevel: 85,
        },
        aggregateScore: 80,
        aggregateDescription:
          'The user demonstrated strong commitment to community support and engagement.',
      },
    ];

    navigate(`/user/${id}`, { state: { email, userDetails: mockUserDetails } });
  };

  return {
    handleChangeTab,
    handleSearchChange,
    handleFilterChange,
    handleSortChange,
    handleChangePage,
    handleSelectAllClick,
    handleClick,
    isSelected,
    handleDetailsClick,
  };
};

export const useCommonHandlers = () => {
  const navigate = useNavigate();

  const commonHandlers = {
    handleBack: () => {
      navigate(-1);
    },
  };

  return commonHandlers;
};

export const useUserDetailsHandlers = (
  setSelectedQuestionIndex,
  userDetails,
  setUserDetails,
  email,
) => {
  const navigate = useNavigate();

  // State for dialogs
  const [isApprovalDialogOpen, setIsApprovalDialogOpen] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [isEditScoreDialogOpen, setIsEditScoreDialogOpen] = useState(false);

  // State for rejection
  const [rejectionReason, setRejectionReason] = useState('');
  const [otherRejectionReason, setOtherRejectionReason] = useState('');
  const [rejectionStep, setRejectionStep] = useState(1);

  // Question handling
  const handleQuestionChange = useCallback(
    (index) => {
      setSelectedQuestionIndex(index);
    },
    [setSelectedQuestionIndex],
  );

  // Score editing
  const handleEditScore = useCallback(() => {
    setIsEditScoreDialogOpen(true);
  }, []);

  const handleEditScoreClose = useCallback(() => {
    setIsEditScoreDialogOpen(false);
  }, []);

  // Approval handling
  const handleAccept = useCallback(() => {
    setIsApprovalDialogOpen(true);
  }, []);

  const handleApprovalDialogClose = useCallback(() => {
    setIsApprovalDialogOpen(false);
    navigate('/requests');
  }, [navigate]);

  // Rejection handling
  const handleRejectOpen = useCallback(() => {
    setIsRejectDialogOpen(true);
    setRejectionStep(1);
  }, []);

  const handleRejectClose = useCallback(() => {
    setIsRejectDialogOpen(false);
    setRejectionReason('');
    setOtherRejectionReason('');
    setRejectionStep(1);
  }, []);

  const handleReasonChange = useCallback((event) => {
    setRejectionReason(event.target.value);
  }, []);

  const handleOtherReasonChange = useCallback((event) => {
    setOtherRejectionReason(event.target.value);
  }, []);

  const handleReject = useCallback(() => {
    const finalReason = rejectionReason === 'Other' ? otherRejectionReason : rejectionReason;
    console.log('Rejecting with reason:', finalReason);
    setRejectionStep(2);
  }, [rejectionReason, otherRejectionReason]);

  const handleRejectConfirm = useCallback(() => {
    console.log('Rejection confirmed');
    handleRejectClose();
    navigate('/requests');
  }, [handleRejectClose, navigate]);

  return {
    // Question handling
    handleQuestionChange,

    // Score editing
    handleEditScore,
    handleEditScoreClose,
    isEditScoreDialogOpen,

    // Approval handling
    handleAccept,
    isApprovalDialogOpen,
    handleApprovalDialogClose,

    // Rejection handling
    handleRejectOpen,
    handleRejectClose,
    handleReasonChange,
    handleOtherReasonChange,
    handleReject,
    handleRejectConfirm,
    isRejectDialogOpen,
    rejectionReason,
    otherRejectionReason,
    rejectionStep,

    // Other
    email,
  };
};

export const useReportHandlers = (onUpdateReport) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const handleEditClick = useCallback(() => {
    setIsEditDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsEditDialogOpen(false);
  }, []);

  const handleSaveMetrics = useCallback(
    (updatedMetrics) => {
      const updatedReport = updatedMetrics.reduce((acc, metric) => {
        acc[metric.name.replace(/\s+/g, '')] = metric.value;
        return acc;
      }, {});
      onUpdateReport(updatedReport);
      setIsEditDialogOpen(false);
    },
    [onUpdateReport],
  );

  return {
    isEditDialogOpen,
    handleEditClick,
    handleCloseDialog,
    handleSaveMetrics,
  };
};
