import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchField = ({ search, handleSearchChange }) => (
  <TextField
    fullWidth
    variant="outlined"
    placeholder="Search by email..."
    value={search}
    onChange={handleSearchChange}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      sx: { borderRadius: '20px' },
    }}
  />
);

export default SearchField;
