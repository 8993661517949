import React, { useState } from 'react';
import { Button, TextField, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { textFieldStyle } from './AuthStyles';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Mock forgot password logic
    console.log('Forgot Password for', email);
    navigate('/login');
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 400, margin: 'auto', mt: 8 }}>
      <Typography color="#e0680a" fontWeight="500" variant="h4" align="center" gutterBottom>
        Forgot Password
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={textFieldStyle}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ bgcolor: '#e0680a', mt: 2, mb: 2 }}
        >
          Reset Password
        </Button>
      </form>
    </Box>
  );
};

export default ForgotPassword;
