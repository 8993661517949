import React from 'react';
import { Typography } from '@mui/material';
import PageContainer from './PageContainer';

const Feedback = () => {
  return (
    <PageContainer title="Feedback">
      <Typography variant="body1">
        This is the Feedback page. You can display more Feedback based information here.
      </Typography>
    </PageContainer>
  );
};

export default Feedback;
