import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import Calendar from './widgets/Calendar';
import NewUsers from './widgets/NewUsers';
import ActiveUsers from './widgets/ActiveUsers';
import EstimatedLifetimeValue from './widgets/EstimatedLifetimeValue';
import AverageSessionTime from './widgets/AverageSessionTime';
import CustomerAcquisitionCost from './widgets/CustomerAcquisitionCost';
import ConversionRate from './widgets/ConversionRate';
import ROI from './widgets/ROI';
import DownloadsVsActivations from './widgets/DownloadsVsActivations';
import UserChurn from './widgets/UserChurn';
import Retention from './widgets/Retention';
import Survey from './widgets/Survey';
import RecentFeedback from './widgets/RecentFeedback';
import RecentSubscriptions from './widgets/RecentSubscriptions';

const Dashboard = () => {
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          Dashboard
        </Typography>
        <Calendar />
      </Box>

      <Grid container spacing={3} sx={{ flexGrow: 1, overflow: 'auto' }}>
        <Grid item xs={12} md={3}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2, height: '100%' }}>
            <NewUsers />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2, height: '100%' }}>
            <ActiveUsers />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2, height: '100%' }}>
            <EstimatedLifetimeValue />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2, height: '100%' }}>
            <AverageSessionTime />
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2, height: '100%' }}>
            <CustomerAcquisitionCost />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2, height: '100%' }}>
            <ConversionRate />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2, height: '100%' }}>
            <ROI />
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2, height: '100%' }}>
            <DownloadsVsActivations />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2, height: '100%' }}>
            <UserChurn />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2, height: '100%' }}>
            <Retention />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2, height: '100%' }}>
            <Survey />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2, height: '100%' }}>
            <RecentFeedback />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ bgcolor: 'white', borderRadius: 2, height: '100%' }}>
            <RecentSubscriptions />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
