import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const Approve = ({ open, onClose, userEmail }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 16,
          padding: '16px',
          textAlign: 'center',
          maxWidth: '400px',
        },
      }}
    >
      <DialogContent sx={{ pb: 2 }}>
        {' '}
        <Box
          sx={{
            p: 2,
            width: 60,
            height: 60,
            borderRadius: '50%',
            backgroundColor: 'success.main',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
          }}
        >
          <CheckIcon sx={{ fontSize: 40, color: 'white' }} />
        </Box>
        <Typography variant="body2" sx={{ mt: 3, mb: 1 }}>
          You have successfully approved a new user
        </Typography>
        <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
          {' '}
          {userEmail}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pt: 0 }}>
        {' '}
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            bgcolor: 'warning.main',
            color: 'white',
            '&:hover': {
              bgcolor: 'warning.dark',
            },
            minWidth: 300,
            height: 48,
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Approve;
