import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const rejectionReasons = [
  'There are missing or incomplete answers in the video submission.',
  'The information provided did not meet the application requirements.',
  'The responses did not adequately reflect the values and goals of the community.',
];

const Reject = ({
  open,
  onClose,
  onReject,
  onReasonChange,
  onOtherReasonChange,
  onRejectConfirm,
  reason,
  otherReason,
  step,
  userEmail,
}) => {
  return (
    <>
      <Dialog open={open && step === 1} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ textAlign: 'left', fontWeight: 'bold' }}>Reject Application</DialogTitle>
        <DialogContent>
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              textAlign: 'left',
              mb: 2,
              mt: 1,
            }}
          >
            Before you proceed, please provide a reason for rejecting this application
          </Typography>
          <RadioGroup value={reason} onChange={onReasonChange}>
            {rejectionReasons.map((text, index) => (
              <FormControlLabel
                key={index}
                value={text}
                control={<Radio />}
                label={text}
                sx={{ mb: 2, borderBottom: '1px solid #e0e0e0', pb: 2 }}
              />
            ))}
            <FormControlLabel value="Other" control={<Radio />} label="Other" />
          </RadioGroup>
          {reason === 'Other' && (
            <TextField
              fullWidth
              multiline
              rows={3}
              value={otherReason}
              onChange={onOtherReasonChange}
              placeholder="Please specify the reason"
              sx={{ mt: 2 }}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: '24px' }}>
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{
              minWidth: 200,
              height: 48,
              mr: 2,
              color: 'warning.main',
              borderColor: 'warning.main',
              '&:hover': {
                backgroundColor: 'warning.light',
                borderColor: 'warning.main',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onReject}
            variant="contained"
            disabled={!reason || (reason === 'Other' && !otherReason)}
            sx={{
              minWidth: 200,
              height: 48,
              backgroundColor: 'warning.main',
              color: 'white',
              '&:hover': {
                backgroundColor: 'warning.dark',
              },
              '&.Mui-disabled': {
                backgroundColor: 'action.disabledBackground',
                color: 'action.disabled',
              },
            }}
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open && step === 2}
        onClose={onClose}
        PaperProps={{
          style: {
            borderRadius: 16,
            padding: '24px',
            textAlign: 'center',
            maxWidth: '400px',
          },
        }}
      >
        <DialogContent sx={{ pb: 2 }}>
          <Box
            sx={{
              width: 60,
              height: 60,
              borderRadius: '50%',
              backgroundColor: 'error.main',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 auto 24px',
            }}
          >
            <CloseIcon sx={{ fontSize: 40, color: 'white' }} />
          </Box>
          <Typography variant="body1" sx={{ mb: 2 }}>
            You have rejected the application for
          </Typography>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold' }}>
            {userEmail}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', pt: 0 }}>
          <Button
            onClick={onRejectConfirm}
            variant="contained"
            sx={{
              minWidth: 200,
              height: 48,
              backgroundColor: 'warning.main',
              color: 'white',
              '&:hover': {
                backgroundColor: 'warning.dark',
              },
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Reject;
