import React from 'react';
import { Box, Typography } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

export const DonutChart = ({ value, color, name }) => {
  const data = [{ value: value }, { value: 100 - value }];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <ResponsiveContainer width="100%" height={225}>
        {' '}
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={67}
            outerRadius={82}
            fill="#8884d8"
            dataKey="value"
            startAngle={90}
            endAngle={-270}
            strokeWidth={0}
          >
            <Cell fill={color} />
            <Cell fill="#e0e0e0" />
          </Pie>
          <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fontSize="36">
            {`${value}%`}
          </text>
        </PieChart>
      </ResponsiveContainer>
      <Typography variant="body1" sx={{ mt: -1.5 }}>
        {name}
      </Typography>
    </Box>
  );
};
