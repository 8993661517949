import React, { useState } from 'react';
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';

const RecentFeedback = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);

  // Mock data - in a real application, this would come from an API or props
  const feedbackData = [
    {
      id: 1,
      user: 'Sam Smith',
      type: 'User',
      rating: 5.0,
      feedback: 'Great app! Really helps me stay organized.',
    },
    {
      id: 2,
      user: 'John Doe',
      type: 'Parent',
      rating: 5.0,
      feedback: 'My kids love using this. Its both fun and educational.',
    },
    {
      id: 3,
      user: 'Emma Brown',
      type: 'Parent',
      rating: 5.0,
      feedback: 'Intuitive interface and helpful features. Highly recommend!',
    },
    {
      id: 4,
      user: 'William Martinez',
      type: 'User',
      rating: 5.0,
      feedback: 'Excellent customer support. They resolved my issue quickly.',
    },
  ];

  const handleOpenDialog = (feedback) => {
    setSelectedFeedback(feedback);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box sx={{ p: 1.5, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography variant="subtitle2" component="h2" sx={{ fontWeight: 'medium' }}>
          Recent Feedback
        </Typography>
        <Tooltip title="Most recent user feedback and ratings">
          <IconButton size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ flexGrow: 1, border: 'none', boxShadow: 'none' }}
        elevation={0}
      >
        <Table sx={{ minWidth: 650 }} aria-label="recent feedback table">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Rating</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbackData.map((row) => (
              <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.user}
                </TableCell>
                <TableCell align="right">{row.type}</TableCell>
                <TableCell align="right">{row.rating.toFixed(1)}</TableCell>
                <TableCell align="right">
                  <IconButton size="small" onClick={() => handleOpenDialog(row)}>
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Feedback Details</DialogTitle>
        <DialogContent>
          {selectedFeedback && (
            <>
              <Typography variant="body1" gutterBottom>
                <strong>User:</strong> {selectedFeedback.user}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Type:</strong> {selectedFeedback.type}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Rating:</strong> {selectedFeedback.rating.toFixed(1)}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Feedback:</strong> {selectedFeedback.feedback}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            sx={{
              textTransform: 'none',
              color: '#e0680a',
              borderColor: 'black',
              '&:hover': {
                borderColor: 'black',
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RecentFeedback;
