import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation } from 'react-router-dom';
import { useCommonHandlers } from './Handlers';

const PageContainer = ({ children, title }) => {
  const location = useLocation();
  const { handleBack } = useCommonHandlers();

  const showBackButton = location.pathname !== '/dashboard';

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {showBackButton && (
          <Button
            startIcon={<ArrowBackIosIcon fontSize="small" />}
            onClick={handleBack}
            sx={{
              mr: 1,
              color: 'warning.main',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              textTransform: 'none',
              padding: '1px 1px',
              '& .MuiButton-startIcon': {
                marginRight: '-4px',
                marginLeft: '-2px',
              },
              '& .MuiSvgIcon-root': {
                fontSize: '16px',
              },
              typography: 'body2',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Back
          </Button>
        )}
        {title && (
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {title}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          padding: 3,
          borderRadius: '16px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          height: 'calc(100vh - 140px)',
          overflow: 'auto',
          flexGrow: 1,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PageContainer;
