import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { textFieldStyle } from './AuthStyles';

const Login = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const navigate = useNavigate();
  const { login } = useAuth(); // Use auth context

  const handleSubmit = (e) => {
    e.preventDefault();
    const success = login(credentials);
    if (success) {
      navigate('/dashboard');
    } else {
      alert('Invalid credentials. Please try again.');
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 400, margin: 'auto', mt: 8 }}>
      <Typography color="#e0680a" fontWeight="500" variant="h4" align="center" gutterBottom>
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label="Username"
          value={credentials.username}
          onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
          required
          sx={textFieldStyle}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Password"
          type="password"
          value={credentials.password}
          onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
          required
          sx={textFieldStyle}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ bgcolor: '#e0680a', mt: 2, mb: 2 }}
        >
          Log In
        </Button>
      </form>
      <Box display="flex" justifyContent="space-between">
        <Link
          color="#e0680a"
          onClick={() => navigate('/forgot-password')}
          sx={{ cursor: 'pointer' }}
        >
          Forgot Password?
        </Link>
        <Link color="#e0680a" onClick={() => navigate('/signup')} sx={{ cursor: 'pointer' }}>
          Sign Up
        </Link>
      </Box>
    </Box>
  );
};

export default Login;
