import React from 'react';
import { Box } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { NumberedButton } from '../../../styles/StyledComponents';

const PaginationButtons = ({ page, pageCount, handleChangePage }) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
    {[...Array(Math.min(4, pageCount))].map((_, index) => (
      <NumberedButton key={index} active={page === index} onClick={() => handleChangePage(index)}>
        {index + 1}
      </NumberedButton>
    ))}
    {pageCount > 4 && (
      <NumberedButton onClick={() => handleChangePage(Math.min(page + 1, pageCount - 1))}>
        <KeyboardDoubleArrowRightIcon fontSize="small" />
      </NumberedButton>
    )}
  </Box>
);

export default PaginationButtons;
